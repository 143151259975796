<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn fab x-small @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h1 style="line-height: 26px; font-size: 26px; text-align: left; margin-left: 10px;" v-if="data && data.name">{{data.name}}</h1>
          <v-btn class="ml-2" fab x-small color="info" @click="openPrintDialog()"><v-icon>mdi-printer</v-icon></v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col class="d-flex flex-column">
          <h3>Available: {{data.ProductLocationJoins&&data.ProductLocationJoins.length>0?data.ProductLocationJoins.reduce((acc,x)=>acc+parseInt(x.available),0):"-"}}</h3>
          <span>PL-{{data.id}} | SKU: {{data.sku}}</span>
          <b v-if="data.regularPrice">Regular Price: ${{data.regularPrice}}</b>
          <b v-if="data.salePrice">Sale Price: ${{data.salePrice}}</b>
          <span style="border: 1px solid rgba(0,0,0,0.25); border-radius: 3px;" class="pa-2" v-if="isAllowed('product', 'viewCostPrice')&&data.ProductSupplierJoins&&data.ProductSupplierJoins.length>0">
            <h3>Cost Price(s) by Supplier:</h3>
            <span v-for="item in data.ProductSupplierJoins" :key="item.id">
              <p>Supplier <b>{{item.Supplier.name}}:</b> ${{item.costPrice}}</p>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Images(<span v-if="this.data.images">{{this.data.images.length}}</span><span v-else>0</span>)</h2>
          <div v-if="this.data.images" class="d-flex flex-row flex-wrap" >
            <div v-for="img in this.data.images" :key="img">
              <div style="position: absolute; margin-top: 10px; margin-left: 10px;" @click="deleteImage(img)"><v-icon>mdi-close</v-icon></div>
              <img :src="img" width="200px;" alt="">
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Barcodes
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer 
            v-bind:buttontext="'Print'" 
            v-bind:small="true" 
            v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex'
import authorizer from '../../components/authorizer.vue'
export default {
  components: {
    authorizer
  },
  data () {
    return {
      printers: [],
      filteredPrinters: [],
      documentTypes: [],
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      data: {},
      isEditable: false,
      loader: false,
      printDialog: {
        isOpen: false,
        quantity: 1,
        jobType: 'productLabel',
        deliveryNote: '',
        printer: '',
      },
    }
  },
  async mounted(){
    window.scrollTo({top: 0});
    await this.getAllData();
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'getUser', 'printInProgress'])
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setPrintInProgress'
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async getAllData(){
      try {
        this.loader = true;

        let res = await axios.get(`${this.getEndpoint}/api/products/forCSR/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data
        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            if(userPrinters&&userPrinters.default.length>0){
              for(let y in userPrinters.default){
                if(printer.data.jobTypes.includes(y)){
                  if(printer.id==userPrinters.default[y]){
                    printer['isDefault'] = true
                    printer.name = printer.name + ` (Default ${y} printer)`
                  }else printer['isDefault'] = false
                }
              }
            }
          })
          this.filteredPrinters = this.printers
        }

      } catch (error) {
        console.error(error);
      } finally {
        this.loader = false;
      }
    },
    openPrintDialog(){
      this.printDialog.isOpen = true
    },
    closePrintDialog(){
      this.printDialog = {
        isOpen: false,
        quantity: 1,
        jobType: '',
        deliveryNote: '',
        printer: ''
      }
    },
    async attemptPrint(e){
      try {
          if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
          e.metadata = this.printDialog
          this.setPrintInProgress(true)
          let res = await axios.post(`${this.getEndpoint}/api/print/productLabel/${this.$route.params.id}/${this.printDialog.quantity}`, e)
          if(res.data.error) throw res.data.error
          this.closePrintDialog()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.closePrintDialog()
      } finally {
        this.setPrintInProgress(false)
      }
    },
  }
}
</script>